import { memo } from 'react';
import { getValidLanguage } from '../../../../../Models/Constants/LanguageTypes';
import Link from '../../../../Shared/Link/link';
import { Header, useTranslation } from 'app/hooks/useTranslations';
import './Logo.scss';

type LogoProps = {
    language: string;
};

export const Logo = ({ language }: LogoProps) => {
    const { t } = useTranslation();

    const logoAltText = t(`${Header}.Logo`, language);

    const selectedLanguage = getValidLanguage(language);

    const logoPaths = {
        no: {
            fullWidth: '/StaticContent/logo/horisontallogo_no.svg',
            mobile: '/StaticContent/logo/horisontallogo_mobile_no.svg',
        },
        en: {
            fullWidth: '/StaticContent/logo/horisontallogo_en.svg',
            mobile: '/StaticContent/logo/horisontallogo_mobile_en.svg',
        },
        se: {
            fullWidth: '/StaticContent/logo/horisontallogo_se.svg',
            mobile: '/StaticContent/logo/horisontallogo_mobile_se.svg',
        },
    };

    const selectedLogoPaths = logoPaths[selectedLanguage];

    return (
        <Link className="logo-link" href="/">
            <picture>
                <source media="(max-width: 1000px)" srcSet={selectedLogoPaths.mobile} />
                <img
                    className={language}
                    src={selectedLogoPaths.fullWidth}
                    alt={logoAltText}
                    width="180"
                    height="107"
                />
            </picture>
        </Link>
    );
};

export default memo(Logo);
